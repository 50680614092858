<template>
  <div class="order">
    <page-header pageName="Orderoverzicht" />
    <div class="py-5" v-if="errored">
      <p>
        Het spijt ons, we kunnen deze informatie momenteel niet ophalen, probeer
        het later opnieuw
      </p>
    </div>
    <div v-else class="container-fluid px-5">
      <div class="row">
        <div class="col-6">
          <search-bar
            type="order"
            v-on:ordersResultUpdate="updateOrders"
            v-on:resetQuery="resetOrders"
          />
        </div>
        <div class="col-2" >
          <DateFilter ref="datefilter"/>
        </div>
        <div class="col-4">
          <pagination-buttons
            :key="this.paginationKey"
            :totalRows="this.totalOrderCount"
            :rowsPerPage="this.rowsPerPage"
            :currentPage="this.currentPage"
            :location="'top'"
            v-on:paginationClick="goToPage"
          />
        </div>
      </div>
      <order-list
        id="order-list"
        :key="this.orderListKey"
        :orders="this.orders"
      />
      <pagination-buttons
        :key="this.paginationKey"
        :totalRows="this.totalOrderCount"
        :rowsPerPage="this.rowsPerPage"
        :currentPage="this.currentPage"
        :location="'bottom'"
        v-on:paginationClick="goToPage"
      />
      <div class="d-flex pt-3 moon-loader-container">
        <moon-loader
          :key="moonLoaderKey"
          class="mx-auto"
          :loading="loading"
          :color="color"
        ></moon-loader>
      </div>
    </div>
  </div>
</template>

<script>
// import axios
import axios from "axios";
import OrderList from "@/components/order/OrderList.vue";
import SearchBar from "@/components/nav/SearchBar.vue";
import DateFilter from "@/components/nav/DateFilter.vue";
import PaginationButtons from "@/components/nav/PaginationButtons.vue";
import PageHeader from "@/components/PageHeader.vue";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
  name: "OrderAll",
  props: ["beginPage"],
  components: {
    PageHeader,
    OrderList,
    MoonLoader,
    SearchBar,
    PaginationButtons,
    DateFilter,
  },
  data() {
    return {
      searchQuery: "",
      currentPage: this.beginPage,
      paginationKey: 1,
      orderListKey: 1,
      moonLoaderKey: 1,
      totalOrderCount: 0,
      orders: [],
      rowsPerPage: 15,
      errored: false,
      errorMsg: "",
      loading: true,
      color: "#131315",
    };
  },
  async beforeMount() {
    await this.getOrders();
  },
  methods: {
    rerenderPaginationButtons() {
      this.paginationKey += 1;
    },
    rerenderOrderList() {
      this.orderListKey += 1;
    },
    rerenderLoading() {
      document.getElementById("order-list-body").style.display = "none";
      this.loading = true;
      this.moonLoaderKey += 1;
    },
    async updateOrders(searchQuery) {
      // document.getElementById("order-list-body").style.display = "none";
      this.rerenderLoading();
      this.currentPage = 1;
      this.searchQuery = searchQuery;
      await this.getOrders();
      this.rerenderOrderList();
    },
    async goToPage(index) {
      // document.getElementById("order-list-body").style.display = "none";
      this.rerenderLoading();
      this.currentPage = index;
      await this.getOrders();
      this.rerenderOrderList();
    },
    async resetOrders() {
      // document.getElementById("order-list-body").style.display = "none";
      this.rerenderLoading();
      this.currentPage = 1;
      this.searchQuery = "";
      await this.getOrders();
      this.rerenderOrderList();
    },
    // Get All Orders
    async getOrders() {

      let dateStart = undefined;
      let dateEnd = undefined

      if(this.$refs && this.$refs.datefilter){
        const dateFilter = this.$refs.datefilter;
        const dates = dateFilter.getDates();

        dateStart = dates.dateStart;
        dateEnd = dates.dateEnd;
      }

      const offset = (this.currentPage - 1) * this.rowsPerPage;
      await axios
        .get(`v1/order`, {
          params: { offset, limit: 15, search: this.searchQuery, dateStart: dateStart, dateEnd: dateEnd },
        })
        .then(async (response) => {
          for (const order of response.data) {
            await this.getCustomerByGuid(order.customer_guid).then(
              (customer) => {
                order.customer = customer;
              }
            );
          }
          this.totalOrderCount = Number(response.headers["x-total-count"]);
          this.rerenderPaginationButtons();
          this.orders = response.data;
        })
        .catch((err) => {
          this.errored = true;
          this.errorModal(err);
        })
        .finally(() => {
          document.getElementById("order-list-body").style.display =
            "table-row-group";
          this.loading = false;
        });
    },
  },
};
</script>

<style>
html {
  overflow-y: scroll;
}
.order #order-list {
  min-height: 100vh;
}
.moon-loader-container {
  position: relative;
  bottom: 80vh;
}
</style>>
