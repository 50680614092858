<template>
    <div id="date-filter">
        <input
            id="date-filter-input"
            @click="toggleDatePicker"
            type="text"
            placeholder="Datums..."
            :value="dateRange"
        />
        <div id="datepicker-container">
            <DatePicker :isRange="true" @update:modelValue="dateSelected">
                <template #footer>
                    <div>
                        <button
                            class="datepicker-button"
                            id="datepicker-clear"
                            v-if="dateStart && dateEnd"
                            @click="clearDates"
                        >
                            Clear
                        </button>
                        <button
                            id="datepicker-submit"
                            @click="editDates"
                            class="datepicker-button"
                        >
                            Aanpassen
                        </button>
                    </div>
                </template>
            </DatePicker>
        </div>
    </div>
</template>
<script>
import { ref } from "vue";
import { DatePicker } from "v-calendar";
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const lastWeek = new Date();
lastWeek.setDate(lastWeek.getDate() - 7);
export default {
    name: "DateFilter",
    components: { DatePicker },
    data() {
        return {
            tempStartDate: undefined,
            tempEndDate: undefined,
            dateStart: undefined,
            dateEnd: undefined,
            date: ref(yesterday),
            dates: {
                start: lastWeek,
                end: yesterday,
            },
        };
    },
    computed: {
        dateRange() {
            if (!this.dateStart || !this.dateEnd) {
                return "Alle datums";
            }
            return `${this.dateStart.toDateString()} - ${this.dateEnd.toDateString()}`;
        },
    },
    methods: {
        dateSelected(e) {
            this.tempStartDate = e.start;
            this.tempEndDate = e.end;
        },
        toggleDatePicker() {
            const datePicker = document.getElementById("datepicker-container");
            datePicker.style.display =
                datePicker.style.display == "block" ? "none" : "block";
        },
        editDates() {
            this.dateStart = this.tempStartDate;
            this.dateEnd = this.tempEndDate;
            this.toggleDatePicker();
        },
        clearDates() {
            this.dateStart = undefined;
            this.dateEnd = undefined;
            this.tempEndDate = undefined;
            this.tempStartDate = undefined;
            this.toggleDatePicker();
        },
        getDates(){
            return {dateStart: this.dateStart, dateEnd: this.dateEnd}
        }
    },
};
</script>
<style>
#date-filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
    position: absolute;
    width: inherit;
}
#date-filter-input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    position: relative;
    width: 100%;
}
#datepicker-container {
    display: none;
    position: relative;
}
.datepicker-button {
    border: none;
    color: white;
    padding: 0.5em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    width: 8em;
}
#datepicker-clear {
    background-color: #72A0C1;
}
#datepicker-submit {
    background-color: #4CAF50;
}
</style>