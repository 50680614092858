<template>
    <h2 class="accordion-header fw-bold">
        <!-- button as header for each orderline, collapses -->
        <button
            class="accordion-toggle collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#order_line_' + orderlineData.id"
            v-on:click="loadFile()"
        >
            <span class="fw-bold ps-3 pe-5">{{
                orderlineData.seller_order_line_ref
            }}</span>
            <div class="status me-auto" v-if="orderlineData.statuses.length">
                <status-dot
                    :status="orderlineData.statuses[0].status"
                    :date="orderlineData.statuses[0].status.date"
                    :externalHandler="orderlineData.external_handling"
                />
            </div>
            <div class="status me-auto" v-else>
                <status-dot />
            </div>
            <div :id="'nextday-indicator-' + orderlineData.id"></div>
            <div class="ms-3">
                <strong class="pe-3">Verwachte verzendatum:</strong
                >{{ sendDate }}
            </div>
            <div
                class="send-date-status-dot ms-3"
                :id="'send-date-status-dot-' + orderline.id"
            ></div>
            <div class="ms-3">
                {{ sendDateStatus[0] }}
            </div>
            <div v-if="isOrderDetail" class="ms-3">
                <strong>{{ index + 1 }}/{{ totalOrderlines }}</strong>
            </div>
            <div class="arrow-container"><i class="fas fa-angle-down"></i></div>
        </button>
    </h2>
    <div :id="'order_line_' + orderline.id" class="accordion-collapse collapse">
        <div class="accordion-body d-flex flex-column">
            <div v-if="!isCanceled" class="row action-buttons ms-auto">
                <button
                    @click="downloadProductionPDF"
                    class="btn btn-primary"
                    style="width: 11em; margin-left: 0.5em"
                >
                    Productiebon
                </button>
                <button
                    v-if="!trackingNumber"
                    @click="showHideDatepicker()"
                    class="btn btn-primary"
                    style="width: 11em; margin-left: 0.5em; position: relative"
                >
                    Verzenddatum <i class="far fa-edit"></i>
                </button>
                <DatePicker
                    :id="'date-picker-' + orderline.id"
                    class="datepicker"
                    :modelValue="'date'"
                    @dayclick="dateSelected"
                />
                <button
                    @click="updateProducer('OCC')"
                    v-if="
                        isOrderDetail &&
                            !orderlineData.producer_guid &&
                            fileIsPresent &&
                            !trackingNumber &&
                            orderlineData.main_status == 4
                    "
                    class="btn btn-primary"
                    style="width: 11em; margin-left: 0.5em"
                >
                    <img
                        src="../../assets/logo_small.png"
                        alt="occ-logo"
                        style="height: 1.2em;"
                    />
                    Naar productie
                </button>
                <button
                    @click="showOutsourceModal(orderline)"
                    v-if="
                        isOrderDetail &&
                            !orderlineData.producer_guid &&
                            fileIsPresent &&
                            !trackingNumber &&
                            orderlineData.main_status == 4
                    "
                    class="btn btn-primary"
                    style="width: 11em; margin-left: 0.5em"
                >
                    Uitbesteden
                </button>
                <button
                    @click="updateProducer('reset')"
                    v-if="
                        isOrderDetail &&
                            orderlineData.producer_guid &&
                            !trackingNumber
                    "
                    class="btn btn-primary"
                    style="width: 11em; margin-left: 0.5em"
                >
                    Reset producent
                </button>
                <button
                    @click="activatePickUp"
                    v-if="
                        orderlineData.producer_guid &&
                            !trackingNumber &&
                            trackingURL == 'PICKUP'
                    "
                    class="btn btn-primary"
                    style="width: 11em; margin-left: 0.5em"
                >
                    Pick Up
                </button>
                <button
                    @click="showSendModal"
                    v-if="
                        orderlineData.producer_guid &&
                            !trackingNumber &&
                            trackingURL != 'PICKUP'
                    "
                    class="btn btn-primary"
                    style="width: 11em; margin-left: 0.5em"
                >
                    Verzenden
                </button>
                <button
                    @click="showUpdateTrackingUrlModal"
                    v-if="
                        orderlineData.producer_guid &&
                            !trackingNumber &&
                            trackingURL != 'PICKUP'
                    "
                    class="btn btn-primary"
                    style="width: 11em; margin-left: 0.5em"
                >
                    Hand. verzenden
                </button>
                <button
                    @click="redownloadSendLabel"
                    v-if="
                        orderlineData.producer_guid &&
                            trackingNumber &&
                            trackingURL != 'PICKUP'
                    "
                    class="btn btn-primary"
                    style="width: 11em; margin-left: 0.5em"
                >
                    Verzendlabel
                </button>
                <a class="w-auto p-0 m-0" target="_blank" :href="trackingURL">
                    <button
                        v-if="
                            orderlineData.producer_guid &&
                                trackingNumber &&
                                trackingURL &&
                                trackingURL != 'PICKUP'
                        "
                        class="btn btn-primary"
                        style="width: 11em; margin-left: 0.5em"
                    >
                        Track and trace
                    </button>
                </a>
                <button
                    @click="setToFinished"
                    v-if="trackingURL && orderlineData.main_status == 6"
                    class="btn btn-primary"
                    style="width: 11em; margin-left: 0.5em"
                >
                    Finished
                    <!-- Sets orderline to finished will also check if all orderliens are finsihed for order -->
                </button>
                <button
                    @click="showCancelModal"
                    v-if="isOrderDetail"
                    class="btn btn-primary"
                    style="width: 11em; margin-left: 0.5em"
                >
                    Annuleren
                    <!-- send canceled mail -->
                </button>
                <button
                    @click="showFixOrderlineModal"
                    class="btn btn-primary"
                    style="width: 3em; margin-left: 0.5em"
                >
                    <i class="fas fa-wrench"></i>
                </button>
                <button
                    :id="'printed-' + orderlineData.guid"
                    @click="updatePrinted"
                    v-if="isProductionDetail && !trackingNumber"
                    class="btn btn-primary"
                    style="width: 3em; margin-left: 0.5em"
                >
                    <i class="fas fa-print"></i>
                </button>
                <button
                    :id="'finished-' + orderlineData.guid"
                    @click="updateFinishing"
                    v-if="isProductionDetail && !trackingNumber"
                    class="btn btn-primary"
                    style="width: 3em; margin-left: 0.5em"
                >
                    <i class="fas fa-cut"></i>
                </button>
                <button
                    :id="'external_handling-' + orderlineData.guid"
                    @click="showExternalHandlingModal"
                    v-if="isProductionDetail && !trackingNumber"
                    class="btn btn-primary"
                    style="width: 4em; margin-left: 0.5em"
                >
                    <i class="fas fa-industry"></i> ▼
                </button>
                <div id=""></div>
            </div>
            <!-- <div v-else class="row action-buttons ms-auto">
          <button class="btn btn-primary" style="width: 11em; margin-left: 0.5em">
            Opnieuw
        </button>
      </div> -->
            <div class="row orderline-info">
                <div class="col-4">
                    <div class="row">
                        <div class="col-7">
                            <canvas
                                @dblclick="openFilePreview()"
                                :id="'canvas-' + orderline.id"
                            ></canvas>
                        </div>
                        <div class="col-5 file-buttons">
                            <h4>Bestand</h4>
                            <p v-if="fileIsPresent">
                                Versie {{ orderlineData.files[0].version }}
                            </p>
                            <button
                                v-if="
                                    isOrderDetail &&
                                        !trackingNumber &&
                                        !isCanceled
                                "
                                :id="'upload-button-' + orderline.id"
                                @click="clickFileInput"
                            >
                                <i class="fas fa-upload"></i>
                                <div>Upload</div>
                            </button>
                            <label>
                                <input
                                    v-if="isOrderDetail"
                                    class="d-none"
                                    :id="'file-input-button-' + orderline.id"
                                    type="file"
                                    accept="application/pdf"
                                    @change="uploadAction($event)"
                                />
                            </label>
                            <button
                                v-if="isProductionDetail"
                                class="d-none"
                                v-on:click="submitFile()"
                            >
                                Submit
                            </button>
                            <button
                                :id="'download-button-' + orderline.id"
                                v-on="{
                                    click: fileIsPresent
                                        ? downloadAction
                                        : null,
                                }"
                            >
                                <i class="fas fa-download"></i>
                                <div>Download</div>
                            </button>
                            <!-- v-if="orderlineData.main_status == 3" -->
                            <button
                                v-if="orderlineData.main_status == 3"
                                class="mt-5 btn btn-primary d-flex justify-content-center"
                                @click="openCheckFileModal"
                            >
                                Controleren
                            </button>
                        </div>
                    </div>
                    <div class="load-layer" :id="'load-layer-' + orderline.id">
                        <moon-loader
                            :key="moonLoaderKey"
                            class="mx-auto moon-loader"
                            :loading="loading"
                            :color="color"
                        >
                        </moon-loader>
                    </div>
                </div>
                <div class="col-8">
                    <div class="row">
                        <div class="col-4 text-start px-3">
                            <div class="row">
                                <h4 class="mb-3">
                                    {{ orderline.product.name }}
                                </h4>
                                <h5>{{ orderline.guid }}</h5>
                                <p class="my-0"></p>
                                <table style="width:100%">
                                    <tr>
                                        <td><strong>Oplage:</strong></td>
                                        <td>{{ orderlineData.run_volume }}</td>
                                    </tr>
                                    <tr
                                        v-for="(data, index) in orderline
                                            .product.product_json"
                                        :key="index"
                                    >
                                        <td>
                                            <strong>{{ index }}:</strong>
                                        </td>
                                        <td>{{ data }}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <h4 class="mb-3">
                                        Orderregel notities
                                        <!-- <h5>Coming soon</h5> -->
                                        <button
                                            @click="showOrderlineCommentModal"
                                            class="btn btn-primary ms-2"
                                        >
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </h4>
                                    <div
                                        v-if="orderlineComments.length"
                                        class="comment-table p-3"
                                    >
                                        <table class="w-100">
                                            <tr
                                                class="border-bottom"
                                                v-for="comment of orderlineComments"
                                                :key="comment.id"
                                            >
                                                <td style="width: 60%">
                                                    {{ comment.body }}
                                                </td>
                                                <td
                                                    style="width: 20%"
                                                    class="align-text-top ps-2"
                                                >
                                                    <strong>{{
                                                        comment.userEmail
                                                    }}</strong>
                                                    <br />
                                                    <strong>{{
                                                        formatToShowDate(
                                                            comment.createdAt
                                                        )
                                                    }}</strong>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 text-start">
                            <div class="row">
                                <h4>
                                    Verzendgegevens
                                    <i
                                        id="edit-send-data"
                                        style="font-size:0.7em;"
                                        class="far fa-edit"
                                        @click.stop="showSendDataUpdateModal"
                                    >
                                    </i>
                                </h4>
                                <div
                                    class="edit-send-data-modal"
                                    :id="'edit-send-data-modal-' + orderline.id"
                                >
                                    <h4
                                        class="pb-4"
                                        style="border-bottom: 1px black solid"
                                    >
                                        Verzendgegevens bewerken:
                                        <i
                                            @click="closeSendDataUpdateModal"
                                            style="cursor: pointer;"
                                            class="fas fa-times float-end me-3"
                                        ></i>
                                    </h4>
                                    <br />
                                    <form
                                        :id="
                                            'update-send-data-form-' +
                                                orderline.id
                                        "
                                        @submit.prevent="updateSendData($event)"
                                    >
                                        <div class="form-group row">
                                            <label
                                                for="input-name"
                                                class="col-sm-2 col-form-label"
                                                >Naam</label
                                            >
                                            <div class="col-sm-10">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="input-name"
                                                    name="name"
                                                    :value="
                                                        orderlineData.address
                                                            .name
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label
                                                for="input-company"
                                                class="col-sm-2 col-form-label"
                                                >Bedrijf</label
                                            >
                                            <div class="col-sm-10">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="input-company"
                                                    name="company"
                                                    :value="
                                                        orderlineData.address
                                                            .company
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label
                                                for="input-address"
                                                class="col-sm-2 col-form-label"
                                                >Adres</label
                                            >
                                            <div class="col-sm-10">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="input-address"
                                                    name="address"
                                                    :value="
                                                        orderlineData.address
                                                            .address
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label
                                                for="input-postal-code"
                                                class="col-sm-2 col-form-label"
                                                >Postcode</label
                                            >
                                            <div class="col-sm-10">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="input-postal-code"
                                                    name="postal_code"
                                                    :value="
                                                        orderlineData.address
                                                            .postal_code
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label
                                                for="input-city"
                                                class="col-sm-2 col-form-label"
                                                >Stad</label
                                            >
                                            <div class="col-sm-10">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="input-city"
                                                    name="city"
                                                    :value="
                                                        orderlineData.address
                                                            .city
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label
                                                for="input-country"
                                                class="col-sm-2 col-form-label"
                                                >Land</label
                                            >
                                            <div class="col-sm-10">
                                                <select
                                                    v-if="
                                                        orderlineData.address
                                                            .country == 'NL'
                                                    "
                                                    class="form-control"
                                                    id="country"
                                                    name="country"
                                                >
                                                    <option selected value="NL"
                                                        >NL</option
                                                    >
                                                    <option value="BE"
                                                        >BE</option
                                                    >
                                                </select>
                                                <select
                                                    v-else
                                                    type="text"
                                                    class="form-control"
                                                    id="input-country"
                                                    name="country"
                                                >
                                                    <option selected value="BE"
                                                        >BE</option
                                                    >
                                                    <option value="NL"
                                                        >NL</option
                                                    >
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label
                                                for="input-phonenumber"
                                                class="col-sm-2 col-form-label"
                                                >Telefoon</label
                                            >
                                            <div class="col-sm-10">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="input-phonenumber"
                                                    name="phonenumber"
                                                    :value="
                                                        orderlineData.address
                                                            .phonenumber
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label
                                                for="input-email"
                                                class="col-sm-2 col-form-label"
                                                >Email</label
                                            >
                                            <div class="col-sm-10">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="input-email"
                                                    name="email"
                                                    :value="
                                                        orderlineData.address
                                                            .email
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-10">
                                                <button
                                                    type="submit"
                                                    class="btn btn-primary"
                                                >
                                                    Bewerken
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <p>
                                    {{ orderlineData.address.company }} t.a.v.
                                    {{ orderlineData.address.name }}
                                    <br />
                                    {{ orderlineData.address.address }}
                                    <br />
                                    {{ orderlineData.address.postal_code }}
                                    {{ orderlineData.address.city }} ({{
                                        orderlineData.address.country
                                    }})
                                    <br v-if="orderlineData.address.email" />
                                    {{ orderlineData.address.email }}
                                    <br
                                        v-if="orderlineData.address.phonenumber"
                                    />
                                    {{ orderlineData.address.phonenumber }}
                                </p>
                            </div>
                            <div class="row">
                                <div>
                                    <strong>Geprint: </strong>
                                    <span>{{
                                        orderlineData.printed_qty || 0
                                    }}</span>
                                </div>
                                <div>
                                    <strong>Inschiet geprint: </strong>
                                    <span>{{
                                        orderlineData.extra_printed_qty || 0
                                    }}</span>
                                </div>
                                <div>
                                    <strong>Aantal geleverd: </strong>
                                    <span>{{
                                        orderlineData.delivered_qty || 0
                                    }}</span>
                                </div>
                            </div>
                            <div
                                class="row"
                                v-if="orderlineData.external_handling"
                            >
                                <div>
                                    <strong>Afwerker: </strong>
                                    <span>{{
                                        orderlineData.external_handling || "-"
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 text-start">
                            <h4 class="border-bottom">Laatste statusupdates</h4>
                            <div id="statuses-container">
                                <table class="w-100">
                                    <tr
                                        class="border-bottom"
                                        v-for="status of orderlineData.statuses"
                                        :key="status.id"
                                    >
                                        <td>
                                            {{ status.status }}
                                            <br />
                                            {{ status.comment }}
                                        </td>
                                        <td v-if="status.date">
                                            {{ formatToShowDate(status.date) }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :id="'file-check-modal-' + orderline.guid"
            class="file-check-modal"
        >
            <p class="d-flex justify-content-end">
                <button @click="closeCheckFileModal" class="btn btn-primary">
                    X
                </button>
            </p>
            <div class="p-5">
                <button @click="acceptFile" class="btn btn-success me-3">
                    Goedkeuren
                </button>
                <button @click="declineFile" class="btn btn-danger ms-3">
                    Afkeuren
                </button>
            </div>
        </div>
    </div>
    <div
        :id="'fix-orderline-modal-' + orderline.guid"
        class="fix-orderline-modal"
    >
        <button
            @click="closeFixOrderlineModal"
            class="btn btn-primary float-end"
        >
            X
        </button>
        <div class="border-bottom m-3">
            <h2>Orderregel aanpassen</h2>
            <p>Zet deze order volledig op klaar</p>
            <small>Voor als de orderregel op open blijft staan</small>
            <button
                @click="forceSetToFinished"
                class="btn btn-primary"
                style="width: 11em; margin-left: 0.5em"
            >
                Finished
            </button>
        </div>
    </div>
    <div
        class="text-start ps-3 out-source-modal"
        :id="'out-source-modal-' + orderline.guid"
    >
        <div class="d-flex border-bottom">
            <h2 class="py-2 me-auto">Productie uitbesteden</h2>
            <button @click="closeOutsourceModal" class="btn btn-primary">
                X
            </button>
        </div>
        <div class="mt-3 pe-3">
            <form
                @submit.prevent="outsourceOrderline($event)"
                id="outsource-form"
            >
                <label class="h4" for="producer">Producenten</label>
                <br />
                <select class="form-select" name="producer" id="producer">
                    <option value="PrintCom">Print.com</option>
                    <option value="Probo">Probo</option>
                    <option value="Printselect">Printselect</option>
                    <option value="DWD">Drukwerkdeal</option>
                </select>
                <label class="h4 mt-3" for="purchase-price">Inkoopprijs</label>
                <br />
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">€</span>
                    <input
                        required
                        name="purchase-price"
                        type="text"
                        class="form-control"
                        placeholder="100,00"
                        aria-label="purchase-price"
                        aria-describedby="basic-addon1"
                    />
                </div>
                <div>
                    <label class="h4" for="reference">Referentie</label>
                    <input
                        required
                        class="form-control"
                        type="text"
                        name="reference"
                    />
                </div>
                <div class="text-center mt-4">
                    <button class="btn btn-primary align-center" type="submit">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="text-start send-modal" :id="'send-modal-' + orderline.guid">
        <button @click="closeSendModal" class="btn btn-primary float-end">
            X
        </button>
        <div class="border-bottom m-3">
            <h2>Verzend orderline</h2>
        </div>
        <div class="p-3">
            <form @submit.prevent="downloadSendLabel($event)">
                <div class="mb-3">
                    <label for="carrier">Verzender</label>
                    <br />
                    <select class="form-select" name="carrier" id="carrier">
                        <option selected value="PostNL">PostNL</option>
                        <option value="DHL">DHL</option>
                    </select>
                </div>
                <div>
                    <label for="label-qty">Aantal labels:</label>
                    <input
                        value="1"
                        class="form-control"
                        type="text"
                        name="qty"
                        id="label-qty"
                    />
                </div>
                <div class="mt-3">
                    <label for="express-shipment">Express zending?</label>
                    <input
                        class="form-check-input"
                        type="checkbox"
                        name="express-shipment"
                        id="express-shipment"
                    />
                </div>
                <div class="mt-5">
                    <button class="btn btn-primary" type="submit">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div
        :id="'external-handling-modal-' + orderlineData.guid"
        class="external-handling-modal"
    >
        <button
            @click="closeExternalHandlingModal"
            class="btn btn-primary float-end"
        >
            X
        </button>
        <h2 class="py-2 me-auto mt-5">Externe afwerking</h2>
        <div class="d-flex justify-content-center w-100 h-100">
            <div class="d-flex border-bottom"></div>
            <div class="mt-3 pe-3">
                <form
                    @submit.prevent="updateExternalHandling($event)"
                    id="outsource-form"
                >
                    <label class="h4" for="external">Externe:</label>
                    <br />
                    <select class="form-select" name="external" id="external">
                        <option
                            value=""
                            :selected="!orderlineData.external_handling"
                            >Geen</option
                        >
                        <option
                            value="Ardon"
                            :selected="
                                orderlineData.external_handling === 'Ardon'
                            "
                            >Ardon</option
                        >
                        <option
                            value="GSB"
                            :selected="
                                orderlineData.external_handling === 'GSB'
                            "
                            >GSB</option
                        >
                        <option
                            value="Bonzet"
                            :selected="
                                orderlineData.external_handling === 'Bonzet'
                            "
                            >Bonzet</option
                        >
                        <option
                            value="Roggeband"
                            :selected="
                                orderlineData.external_handling === 'Roggeband'
                            "
                            >Roggeband</option
                        >
                        <option
                            value="Piant"
                            :selected="
                                orderlineData.external_handling === 'Piant'
                            "
                            >Piant</option
                        >
                        <option
                            value="RW"
                            :selected="orderlineData.external_handling === 'RW'"
                            >RW</option
                        >
                        <option
                            value="Overige"
                            :selected="
                                orderlineData.external_handling === 'Overige'
                            "
                            >Overige</option
                        >
                    </select>
                    <div class="text-center mt-4">
                        <button
                            class="btn btn-primary align-center"
                            type="submit"
                        >
                            Updaten
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div
        class="text-start manual-tracking-modal"
        :id="'manual-tracking-modal-' + orderline.guid"
    >
        <button
            @click="closeUpdateTrackingUrlModal"
            class="btn btn-primary float-end"
        >
            X
        </button>
        <div class="border-bottom m-3">
            <h2>Handmatige tracking</h2>
        </div>
        <div class="p-3">
            <form @submit.prevent="updateTrackingData($event, orderLineData)">
                <div>
                    <label for="tracking-url">Tracking url:</label>
                    <br />
                    <input
                        class="form-control"
                        type="text"
                        name="tracking-url"
                        :id="'tracking-url-' + orderlineData.id"
                    />
                </div>
                <br />
                <div>
                    <label for="tracking-code">Tracking code:</label>
                    <br />
                    <input
                        class="form-control"
                        type="text"
                        name="tracking-code"
                        :id="'tracking-code-' + orderlineData.id"
                    />
                </div>
                <div class="mt-5">
                    <button class="btn btn-primary" type="submit">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div
        class="text-start multiple-addresses-modal"
        :id="'multiple-addresses-modal-' + orderline.guid"
    >
        <button
            @click="closeMultipleAddressModal"
            class="btn btn-primary float-end"
        >
            X
        </button>
        <div class="border-bottom m-3">
            <h2>Kies adres:</h2>
        </div>
        <div
            v-if="validatedAddresses"
            class="p-3"
            id="multiple-addresses-container"
        >
            <div
                class="border address-block"
                v-for="address of validatedAddresses"
                :key="address"
                @click="downloadSendLabel($event, address)"
            >
                <p class="m-0">Straat: {{ address.street }}</p>
                <p class="m-0">Huisnummer: {{ address.building }}</p>
                <p class="m-0">
                    Toevoeging: {{ address.buildingNumberAddition }}
                </p>
                <p class="m-0">Postcode: {{ address.postcode }}</p>
                <p class="m-0">Stad: {{ address.locality }}</p>
                <p class="m-0">Land: {{ address.country }}</p>
            </div>
        </div>
    </div>
    <div class="text-start cancel-modal" :id="'cancel-modal-' + orderline.guid">
        <button @click="closeCancelModal" class="btn btn-primary float-end">
            X
        </button>
        <div class="p-3">
            <h1>Order annuleren</h1>
            <p>
                U bent van plan om een orderegel te annuleren. Typ de referentie
                ter extra beveiliging
            </p>
            <p>Orderregel referentie: {{ orderline.seller_order_line_ref }}</p>
            <form @submit.prevent="cancelOrderline($event)">
                <input
                    name="check"
                    id="cancel-order-check"
                    class="form-control"
                    type="text"
                    placeholder="Orderregel referentie"
                />
                <br />
                <button type="submit" class="btn btn-danger">
                    Orderregel Annuleren
                </button>
            </form>
        </div>
    </div>
    <div
        class="orderline-comment-modal"
        :id="'orderline-comment-modal-' + orderline.guid"
    >
        <p class="d-flex justify-content-end">
            <button @click="closeCommentModal" class="btn btn-primary">
                X
            </button>
        </p>
        <form
            @submit="postOrderlineComment($event)"
            class="h-100"
            id="comment-form"
        >
            <textarea
                name="comment"
                id="comment"
                v-on:keydown.enter.prevent="
                    submitFormByEnter($event, 'comment-form')
                "
            ></textarea>
            <br />
            <button type="submit" class="btn btn-primary">Submit</button>
        </form>
    </div>
</template>
<script>
// import axios from "axios";
import StatusDot from "@/components/StatusDot.vue";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import { DatePicker } from "v-calendar";
import Swal from "sweetalert2";
export default {
    components: {
        StatusDot,
        MoonLoader,
        DatePicker,
    },
    data() {
        return {
            orderlineData: this.orderline,
            orderData: {},
            customerData: {},
            file: this.orderline.files[0],
            fileIsPresent: false,
            loading: false,
            color: "#D80F27",
            moonLoaderKey: 1,
            fileLoaded: false,
            renderingFile: false,
            sendDate: "12-11-2021",
            sendDateStatus: ["Late", "#fff"],
            address: this.orderline.address,
            isCanceled: false,
            validatedAddresses: [],
            sendData: {},
            trackingNumber: this.orderline["tracking_number"],
            trackingURL: this.orderline["tracking_url"],
            orderlineComments: [],
        };
    },
    emits: ["orderlineUpdated", "orderStatusChanged"],
    props: [
        "orderline",
        "index",
        "totalOrderlines",
        "isOrderDetail",
        "isProductionDetail",
    ],
    async beforeMount() {
        this.setSendDate();
        this.sendDateStatus = this.getSendDateStatus();
        if (
            this.orderlineData.statuses.length &&
            this.orderlineData.statuses[0].status === "Geannuleerd"
        ) {
            this.isCanceled = true;
        }
        this.getOrderlineComments(this.orderline.guid)
            .then((result) => {
                this.orderlineComments = result.data;
            })
            .catch((e) => {
                alert("getting orderline comments failed");
                console.log(e);
            });
    },
    async mounted() {
        // await this.getOrderlineComments(this.orderline.guid)
        //   .then((result) => {
        //     this.orderlineComments = result.data;
        //   })
        //   .catch((e) => {
        //     alert("getting orderline comments failed");
        //     console.log(e);
        //   });
        if (this.orderlineData.files.length) this.fileIsPresent = true;
        if (!this.fileIsPresent) {
            document.querySelector(
                `#download-button-${this.orderline.id}`
            ).style.backgroundColor = "darkgrey";
        }

        // tomorrow in YYYY-MM-DD 24:59:59 format
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(23, 59, 59, 999);

        const expectedShippingDate = new Date(
            this.orderlineData.expected_shipping_date
        );

        if (
            this.orderlineData.lead_time <= 1 &&
            expectedShippingDate <= tomorrow
        ) {
            const nextdayIndicator = document.querySelector(
                "#nextday-indicator-" + this.orderlineData.id
            );
            nextdayIndicator.classList.add("nextday");
            nextdayIndicator.textContent = "Nextday";
        }
        document.querySelector(
            `#send-date-status-dot-${this.orderlineData.id}`
        ).style.backgroundColor = `${this.sendDateStatus[1]}`;

        this.loadOrder();
        this.loadCustomer();
        if (this.isProductionDetail) this.formatButtons();

        const trackingUrlInputEl = document.querySelector(
            `#tracking-url-${this.orderline.id}`
        );

        const trackingCodeInputEl = document.querySelector(
            `#tracking-code-${this.orderline.id}`
        );

        trackingUrlInputEl.addEventListener("change", (e) => {
            const parts = e.target.value.split("/");
            // find part which is "tracktrace"
            const index = parts.findIndex((part) => part === "tracktrace");
            // set tracking code to next part
            if (index && index !== parts.length - 1) {
                trackingCodeInputEl.value = parts[index + 1];
            }
        });
    },
    watch: {
        loading: function() {
            if (this.loading)
                document.querySelector(
                    `#load-layer-${this.orderline.id}`
                ).style.display = "flex";
            if (!this.loading) {
                document.querySelector(
                    `#load-layer-${this.orderline.id}`
                ).style.display = "none";
            }
        },
        fileIsPresent: function() {
            if (!this.fileIsPresent)
                document.querySelector(
                    `#download-button-${this.orderline.id}`
                ).style.backgroundColor = "grey";
            if (this.fileIsPresent)
                document.querySelector(
                    `#download-button-${this.orderline.id}`
                ).style.backgroundColor = "#222";
        },
    },
    methods: {
        logOrderline() {
            console.log(this.orderline);
        },
        formatButtons() {
            const buttons = ["printed", "finished", "external_handling"];

            for (let button of buttons) {
                const el = document.getElementById(
                    `${button}-${this.orderlineData.guid}`
                );

                if (el) {
                    if (!this.orderlineData[button]) {
                        el.classList.add("toggled-off");
                    } else {
                        document;
                        el.classList.remove("toggled-off");
                    }
                }
            }
        },
        async loadOrder() {
            if (this.orderline.order) {
                this.orderData = this.orderline.order;
            } else if (this.orderline.order_guid) {
                this.orderData = await this.getOrder(this.orderline.order_guid);
            }
        },
        async loadCustomer() {
            if (this.orderline.customer) {
                this.customerData = this.orderlineData.customer;
            } else if (this.orderlineData.address.customer_guid) {
                this.customerData = await this.getCustomer(
                    this.orderlineData.address.customer_guid
                );
            }
        },
        async loadFile() {
            if (
                !this.fileLoaded &&
                this.orderlineData.files.length &&
                !this.renderingFile
            ) {
                this.renderingFile = true;
                let canvas = document.getElementById(
                    `canvas-${this.orderlineData.id}`
                );
                await this.renderFile(this.file, canvas).then(() => {
                    this.fileLoaded = true;
                    if (!this.fileIsPresent) this.fileIsPresent = true;
                    this.renderingFile = false;
                });
            }
            this.loading = false;
        },
        async renderFile(file, canvas) {
            await this.renderPreview(file, canvas)
                .then((file_size) => {
                    file.size = file_size;
                    file.isLoading = false;
                })
                .catch((err) => {
                    this.errored = true;
                    this.errorModal(err);
                })
                .finally(() => (file.loaded = true));
        },
        async downloadAction() {
            await this.downloadFile(this.file.url, this.orderlineData);
        },
        async viewAction() {},
        clickFileInput() {
            document
                .querySelector(`#file-input-button-${this.orderline.id}`)
                .click();
        },
        async uploadAction(event) {
            this.loading = true;
            const file = event.target.files[0];
            let newVersion = await this.getVersion();
            await this.uploadFile(this.orderlineData.guid, newVersion, file)
                .then(async () => {
                    this.updateStatus("fileUploaded");
                    this.updateOrderline(this.orderlineData.guid, {
                        main_status: 3, // Ready for file check
                    }).then(async () => {
                        this.orderlineData.main_status = 3;
                        await this.reloadOrderline();
                        this.reloadFile();
                        this.syncOrderLineStatus(this.orderlineData.guid);
                    });
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        },
        async getVersion() {
            // Get version
            if (this.orderlineData.files.length) {
                const lastVersion = Math.max.apply(
                    Math,
                    this.orderlineData.files.map(function(file) {
                        return file.version;
                    })
                ); // max version
                return lastVersion + 1;
            } else {
                return 1;
            }
        },
        async reloadOrderline() {
            this.orderlineData = await this.getOrderLine(
                this.orderlineData.guid
            );
        },
        async reloadFile() {
            this.file = this.orderlineData.files[0];
            this.fileLoaded = false;
            this.loadFile();
        },
        setSendDate() {
            const expectedShippingDate = new Date(
                this.orderlineData.expected_shipping_date
            );
            // console.log(expectedShippingDate);
            this.sendDate =
                expectedShippingDate.getDate() +
                "-" +
                (expectedShippingDate.getMonth() + 1) +
                "-" +
                expectedShippingDate.getFullYear();
        },
        getSendDateStatus() {
            // TODO: If actual shipping date use that and expected instead of currentDate
            const expectedShippingDate = new Date(
                this.orderline.expected_shipping_date
            );
            const currentDate = new Date();
            const secondsPassed = Math.round(
                (expectedShippingDate - currentDate) / 1000
            );
            if (secondsPassed >= 0) return ["Op tijd", "#006400"];
            if (secondsPassed <= -86400) return ["Te laat", "#FF0000"];
            return ["Moet weg", "#ffa500"];
        },
        showHideDatepicker() {
            const datePicker = document.querySelector(
                `#date-picker-${this.orderline.id}`
            );
            datePicker.style.display =
                datePicker.style.display == "block" ? "none" : "block";
        },
        async dateSelected(e) {
            this.showHideDatepicker();
            const answer = confirm(
                "Je bent van plan om de verwachtte verzenddatum aan te passen, weet je dit zeker?"
            );
            if (!answer) return;
            const updateJSON = {
                expected_shipping_date: this.formatToSQLDate(e.date),
            };
            await this.updateOrderline(
                this.orderline.guid,
                JSON.stringify(updateJSON)
            )
                .then((response) => {
                    this.orderlineData.expected_shipping_date = new Date(
                        response.expected_shipping_date
                    );
                    this.setSendDate();
                    this.sendDateStatus = this.getSendDateStatus();
                    document.querySelector(
                        `#send-date-status-dot-${this.orderlineData.id}`
                    ).style.backgroundColor = `${this.sendDateStatus[1]}`;
                    this.updateStatus("shippingDateAltered");
                })
                .catch((e) => console.log(e));
        },
        closeSendDataUpdateModal() {
            document.querySelector(
                `#edit-send-data-modal-${this.orderline.id}`
            ).style.display = "none";
        },
        showSendDataUpdateModal() {
            const updateModal = document.querySelector(
                `#edit-send-data-modal-${this.orderline.id}`
            );
            updateModal.style.display = "flex";
            const eventFunction = (e) => {
                if (!e.path) return;
                if (!e.path.includes(updateModal)) {
                    document.body.removeEventListener("click", eventFunction);
                    updateModal.style.display = "none";
                }
            };
            document.body.addEventListener("click", eventFunction);
        },
        async updateSendData(e) {
            const formData = new FormData(e.target);
            const sendData = {};
            sendData["guid"] = this.generateGUID();
            sendData["type"] = "shipping";
            for (let [index, value] of formData) {
                sendData[index] = value;
            }

            await this.createAddress(
                this.orderlineData.address.customer_guid,
                sendData
            )
                .then((response) => {
                    const updateJSON = { address_guid: response.guid };
                    this.updateOrderline(
                        this.orderline.guid,
                        JSON.stringify(updateJSON)
                    )
                        .then(() => {
                            this.orderlineData.address = sendData;
                            this.closeSendDataUpdateModal();
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));
        },
        async downloadProductionPDF() {
            try {
                const productJson = JSON.parse(
                    JSON.stringify(this.orderlineData.product.product_json)
                );

                const shippingDate = this.formatToProductionFileDate(
                    this.orderlineData.expected_shipping_date
                );

                console.log({ shippingDate });

                const shippingDay = this.formatToDayOfWeek(
                    this.orderlineData.expected_shipping_date
                );

                console.log({ shippingDay });

                let pdfData = {
                    orderlineComments: this.orderlineComments,
                    run_volume: this.orderlineData.run_volume,
                    fileUrl: this.file.url,
                    sellerRef: this.customerData.referral,
                    orderLineRef: this.orderlineData.seller_order_line_ref,
                    orderRef: this.orderlineData.product.name,
                    productJson,
                    isPickUp: false,
                    shippingDate,
                    shippingDay,
                    printed_qty: this.orderlineData.printed_qty,
                    extra_printed_qty: this.orderlineData.extra_printed_qty,
                    delivered_qty: this.orderlineData.delivered_qty,
                };
                if (this.orderlineData.tracking_number == "PICKUP")
                    pdfData.isPickUp = true;
                await this.generateProductionPDF(pdfData).then(() => {
                    this.updateStatus("productionFilePrinted");
                });
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async updateProducer(producer, producerRef = "") {
            const producerGUIDS = {
                reset: null,
                OCC: "1b67fe71-9dda-488f-a182-99839cd2fc9a",
                PrintCom: "71e6d1b0-7331-4c57-834c-7ae0561d0d49",
            };

            const updateJSON = {
                producer_guid: producerGUIDS[producer],
                main_status: 5, // In production
            };

            if (!producerGUIDS[producer]) updateJSON["producer_ref"] = null;
            if (!producerGUIDS[producer]) updateJSON["main_status"] = 4; // Ready for outsourcing
            if (producerRef) updateJSON["producer_ref"] = producerRef;

            if (producer == "OCC") {
                updateJSON.printed = 0;
                updateJSON.finished = 0;
            }

            return await this.updateOrderline(this.orderline.guid, updateJSON)
                .then((response) => {
                    this.orderlineData.main_status = updateJSON["main_status"];
                    this.orderlineData.producer_guid = response.producer_guid;
                    if (response.producer_ref)
                        this.orderlineData.producer_ref = response.producer_ref;
                    if (producer == "OCC") {
                        this.updateStatus("inProduction");
                    } else if (producer == "reset") {
                        this.updateStatus("outsourcingCanceled");
                    } else {
                        this.updateStatus("outsourced");
                    }
                    this.syncOrderLineStatus(this.orderlineData.guid);
                    return;
                })
                .catch((e) => console.log(e));
        },
        async updateStatus(status, externalHandler = "") {
            const statuses = {
                closed: {
                    status: "Closed",
                    comment: "De orderregel is afgerond",
                },
                canceled: {
                    status: "Geannuleerd",
                    comment: "De bestelling is geanuleerd",
                },
                outsourced: {
                    status: "Uitbesteed",
                    comment: "De bestelling is uitbesteed",
                },
                outsourcingCanceled: {
                    status: "Uitbesteding geannuleerd",
                    comment: "De uitbesteding is geannuleerd",
                },
                inProduction: {
                    status: "In productie",
                    comment: "De bestelling is in productie",
                },
                shipped: {
                    status: "Verzonden",
                    comment: "De bestelling is verzonden",
                },
                pickup: {
                    status: "Klaar voor Pickup",
                    comment: "De bestelling is klaar voor Pickup",
                },
                productionFilePrinted: {
                    status: "Productiebon geprint",
                    comment: "De productiebon is geprint",
                },
                printed: {
                    status: "Geprint",
                    comment: "De bestelling is geprint",
                },
                notPrinted: {
                    status: "Niet geprint",
                    comment: "De bestelling is niet meer geprint",
                },
                handlingFinished: {
                    status: "Afwerking voltooid",
                    comment: "De bestelling is afgewerkt",
                },
                handlingNotFinished: {
                    status: "Afwerking niet voltooid",
                    comment: "De bestelling is niet afgewerkt",
                },
                finishingOutsourced: {
                    status: "Afwerking uitbesteed",
                    comment: "De afwerking van de bestelling is uitbesteed",
                },
                shippingDateAltered: {
                    status: "Verzenddatum aangepast",
                    comment: "De verzenddatum van de bestelling is aangepast",
                },
                fileUploaded: {
                    status: "Bestand geupload",
                    comment: "Er is een bestand geupload",
                },
                toExternalHandler: {
                    status: "Afwerker",
                    comment: `De bestelling is doorgestuurd naar ${externalHandler}`,
                },
            };
            return await this.updateOrderlineStatus(
                this.orderlineData.guid,
                statuses[status]
            )
                .then((result) => {
                    this.orderlineData.statuses.unshift(result);
                    this.$emit("statusChanged", status, this.orderlineData);
                    return;
                })
                .catch((e) => console.log(e));
        },
        showCancelModal() {
            this.activateBlockOut();
            const el = document.getElementById(
                "cancel-modal-" + this.orderline.guid
            );
            el.style.display = "block";
        },
        closeCancelModal() {
            this.deactivateBlockOut();
            const el = document.getElementById(
                "cancel-modal-" + this.orderline.guid
            );
            el.style.display = "none";
        },
        async cancelOrderline(e) {
            const obj = {};
            for (let [index, value] of new FormData(e.target)) {
                obj[index] = value;
            }

            if (obj.check !== this.orderlineData.seller_order_line_ref) {
                alert("Check komt niet overeen met orderline referentie");
                return;
            }

            await this.updateStatus("canceled")
                .then(async () => {
                    await this.updateOrderline(this.orderlineData.guid, {
                        printed: null,
                        finished: null,
                        external_handling: null,
                        producer_guid: null,
                        main_status: 0,
                    }).then(async () => {
                        this.isCanceled = true;
                        this.orderlineData.main_status = 0;
                        await this.checkOrderStatus(
                            this.orderlineData.order_guid
                        ).then((orderIsClosed) => {
                            if (orderIsClosed) {
                                this.$emit("orderStatusChanged", orderIsClosed);
                            }
                        });
                        this.syncOrderLineStatus(this.orderlineData.guid);
                        this.closeCancelModal();
                    });
                })
                .catch((e) => console.log(e));
        },
        async setToFinished() {
            await this.updateOrderline(this.orderlineData.guid, {
                main_status: 7,
            }).then(async () => {
                this.orderlineData.main_status = 7;
                this.syncOrderLineStatus(this.orderlineData.guid);
                await this.checkOrderStatus(this.orderlineData.order_guid).then(
                    (orderIsClosed) => {
                        if (orderIsClosed) {
                            this.updateStatus("closed");
                            this.$emit("orderStatusChanged", orderIsClosed);
                        }
                    }
                );
            });
        },
        async forceSetToFinished() {
            await this.updateOrderline(this.orderlineData.guid, {
                main_status: 7,
            }).then(async () => {
                this.orderlineData.main_status = 7;
                await this.updateStatus("closed").then(() => {
                    this.closeFixOrderlineModal();
                    this.$emit("orderStatusChanged", true);
                });
                this.syncOrderLineStatus(this.orderlineData.guid);
                await this.checkOrderStatus(this.orderlineData.order_guid).then(
                    (orderIsClosed) => {
                        if (orderIsClosed) {
                            this.updateStatus("closed");
                            this.$emit("orderStatusChanged", orderIsClosed);
                        }
                    }
                );
            });
        },
        showFixOrderlineModal() {
            this.activateBlockOut();
            const el = document.getElementById(
                "fix-orderline-modal-" + this.orderline.guid
            );
            el.style.display = "block";
        },
        closeFixOrderlineModal() {
            this.deactivateBlockOut();
            const el = document.getElementById(
                "fix-orderline-modal-" + this.orderline.guid
            );
            el.style.display = "none";
        },
        showOutsourceModal(orderlineData) {
            this.activateBlockOut();

            const el = document.getElementById(
                "out-source-modal-" + this.orderline.guid
            );

            el.style.display = "block";

            const form = el.getElementById("outsource-form");

            form.orderlineGuid = orderlineData.guid;
        },
        closeOutsourceModal() {
            this.deactivateBlockOut();
            const el = document.getElementById(
                "out-source-modal-" + this.orderline.guid
            );
            el.style.display = "none";
        },
        async outsourceOrderline(e) {
            const formData = new FormData(e.target);
            const outsourceData = {};
            for (let [index, value] of formData.entries()) {
                outsourceData[index] = value;
            }
            await this.updateProducer(
                outsourceData.producer,
                outsourceData.reference
            ).then(() => {
                this.deactivateBlockOut();
                this.closeOutsourceModal();
            });
        },
        async updatePrinted() {
            let printed = !this.orderlineData.printed ? true : false;

            const cb = async (swalValues) => {
                const data = {
                    printed,
                };

                if (swalValues) {
                    data.printed_qty = swalValues[0];
                    data.extra_printed_qty = swalValues[1];
                }

                await this.updateOrderline(this.orderlineData.guid, data).then(
                    () => {
                        this.orderlineData.printed = printed;
                        if (swalValues) {
                            this.orderlineData.printed_qty = swalValues[0];
                            this.orderlineData.extra_printed_qty =
                                swalValues[1];
                        }
                        if (printed) this.updateStatus("printed");
                        if (!printed) this.updateStatus("notPrinted");
                        this.formatButtons();
                    }
                );
            };
            if (printed) {
                this.$swal({
                    title: "Enter your details",
                    html:
                        '<input id="swal-input1" class="swal2-input" placeholder="Aantal geprint">' +
                        '<input id="swal-input2" class="swal2-input" placeholder="Aantal inschiet">',
                    focusConfirm: false,
                    preConfirm: () => {
                        return [
                            document.getElementById("swal-input1").value,
                            document.getElementById("swal-input2").value,
                        ];
                    },
                    didOpen() {
                        const input2 = document.getElementById("swal-input2");
                        input2.addEventListener("keydown", (event) => {
                            if (event.key === "Enter") {
                                Swal.clickConfirm();
                            }
                        });
                    },
                }).then((result) => cb(result.value));
            } else {
                cb(null);
            }
        },
        async updateFinishing() {
            let finished = !this.orderlineData.finished ? true : false;

            const cb = async (swalValues) => {
                const data = {
                    finished,
                };

                if (swalValues) {
                    data.delivered_qty = swalValues[0];
                }

                await this.updateOrderline(this.orderlineData.guid, data).then(
                    () => {
                        this.orderlineData.finished = finished;
                        if (swalValues) {
                            this.orderlineData.delivered_qty = swalValues[0];
                        }
                        if (finished) this.updateStatus("handlingFinished");
                        if (!finished) this.updateStatus("handlingNotFinished");
                        this.formatButtons();
                    }
                );
            };

            if (finished) {
                this.$swal({
                    title: "Enter your details",
                    html:
                        '<input id="swal-input1" class="swal1-input" placeholder="Aantal geleverd">',
                    focusConfirm: false,
                    preConfirm: () => {
                        return [document.getElementById("swal-input1").value];
                    },
                    didOpen() {
                        const input2 = document.getElementById("swal-input1");
                        input2.addEventListener("keydown", (event) => {
                            if (event.key === "Enter") {
                                Swal.clickConfirm();
                            }
                        });
                    },
                }).then((result) => cb(result.value));
            } else {
                cb(null);
            }
        },
        showExternalHandlingModal() {
            this.activateBlockOut();
            const el = document.getElementById(
                "external-handling-modal-" + this.orderline.guid
            );
            el.style.display = "block";
        },
        closeExternalHandlingModal() {
            this.deactivateBlockOut();
            const el = document.getElementById(
                "external-handling-modal-" + this.orderline.guid
            );
            el.style.display = "none";
        },
        updateExternalHandling(event) {
            const formData = new FormData(event.target);
            const values = {};
            for (let [index, value] of formData.entries()) {
                values[index] = value;
            }
            this.updateOrderline(this.orderlineData.guid, {
                external_handling: values.external,
            }).then(() => {
                this.updateStatus("toExternalHandler", values.external);
                this.orderlineData.external_handling = values.external;
                const el = document.getElementById(
                    `external_handling-${this.orderlineData.guid}`
                );

                console.log({ el, orderLineData: this.orderlineData });

                if (el) {
                    if (!this.orderlineData["external_handling"]) {
                        el.classList.add("toggled-off");
                    } else {
                        el.classList.remove("toggled-off");
                    }
                }
                this.closeExternalHandlingModal();
            });
        },
        showSendModal() {
            this.activateBlockOut();
            const el = document.getElementById(
                "send-modal-" + this.orderline.guid
            );
            el.style.display = "block";
        },
        closeSendModal() {
            this.deactivateBlockOut();
            const el = document.getElementById(
                "send-modal-" + this.orderline.guid
            );
            el.style.display = "none";
        },
        showUpdateTrackingUrlModal() {
            this.activateBlockOut();

            const el = document.getElementById(
                "manual-tracking-modal-" + this.orderline.guid
            );

            el.style.display = "block";
        },
        closeUpdateTrackingUrlModal() {
            this.deactivateBlockOut();
            const el = document.getElementById(
                "manual-tracking-modal-" + this.orderline.guid
            );

            el.style.display = "none";
        },
        showMultipleAddressModal() {
            this.activateBlockOut();
            const el = document.getElementById(
                "multiple-addresses-modal-" + this.orderline.guid
            );

            el.style.display = "block";
        },
        closeMultipleAddressModal() {
            this.deactivateBlockOut();
            const el = document.getElementById(
                "multiple-addresses-modal-" + this.orderline.guid
            );
            el.style.display = "none";
        },
        async activatePickUp() {
            // update db
            // status communicator

            await this.updateOrderline(this.orderlineData.guid, {
                tracking_number: "PICKUP",
                tracking_url: "PICKUP",
                actual_shipping_date: this.formatToSQLDate(new Date()),
                main_status: 6,
            }).then(() => {
                this.updateStatus("pickup");
                this.trackingNumber = "PICKUP";
                this.syncOrderLineStatus(this.orderlineData.guid);
            });
        },
        getTrackAndTrace(carrier, trackerCode, country, zipcode) {
            if (carrier === "DHL") {
                return `https://my.dhlparcel.nl/home/tracktrace/${trackerCode}`;
            }
            if (carrier === "PostNL") {
                return `https://jouw.postnl.nl/track-and-trace/${trackerCode}-${country}-${zipcode}`;
            }
            return "";
        },
        async downloadSendLabel(e, prevalidatedAddress = {}) {
            let validatedAddress;
            let validatedAddresses = [];
            let sendData = {};

            this.closeSendModal();
            const el = document.getElementById(
                "multiple-addresses-modal-" + this.orderline.guid
            );
            el.style.display = "none";

            if (Object.keys(this.sendData).length) {
                sendData = this.sendData;
            } else {
                const formData = new FormData(e.target);
                for (let [index, value] of formData.entries()) {
                    sendData[index] = value;
                }
            }
            const carrier = sendData["carrier"];

            if (Object.keys(prevalidatedAddress).length > 1) {
                validatedAddresses = [prevalidatedAddress];
            } else {
                try {
                    validatedAddresses = await this.validateAddress({
                        city: this.orderlineData.address.city,
                        postal_code: this.orderlineData.address.postal_code,
                        address: this.orderlineData.address.address,
                    });
                } catch (error) {
                    console.log(error);
                    this.errorModal(
                        "Adres kan niet gevalideerd worden door Postcode.eu, maak het label handmatig aan in DHL Parcel."
                    );
                    return;
                }
            }
            const { firstName, lastName } = this.dissectName();

            let orderData = {
                city: this.orderlineData.address.city,
                zipCode: this.orderlineData.address.postal_code,
                fullName: this.orderlineData.address.name,
                email: this.customerData.email,
                orderRef: this.orderlineData.seller_order_line_ref,
                country: this.orderlineData.address.country.toUpperCase(),
                firstName: firstName,
                lastName: lastName,
                qty: sendData.qty,
            };

            let addressData = {};

            if (validatedAddresses.length > 1) {
                this.validatedAddresses = validatedAddresses;
                this.sendData = sendData;
                return this.showMultipleAddressModal();
            } else if (validatedAddresses.length === 1) {
                validatedAddress = validatedAddresses[0];
                addressData = {
                    houseNumber: validatedAddress.buildingNumber.toString(),
                    street: validatedAddress.street,
                    addition: validatedAddress.buildingNumberAddition,
                };
            } else {
                addressData = {
                    houseNumber: this.orderlineData.address.building_number,
                    street: validatedAddress.street,
                    addition: validatedAddress.buildingNumberAddition,
                };
            }

            orderData = { ...orderData, ...addressData };

            if (this.orderlineData.address.company) {
                orderData["company"] = this.orderlineData.address.company;
            }

            if (sendData["express-shipment"]) {
                orderData["isExpresser"] = true;
            }

            await this.sendWithCarrier(orderData, carrier)
                .then((result) => {
                    console.log("Send RESULT:", result);
                    this.makeObjectURLFromLabel(result);
                    this.updateStatus("shipped");

                    const trackingUrl = this.getTrackAndTrace(
                        carrier,
                        result.trackerCode,
                        orderData.country,
                        orderData.zipCode
                    );

                    this.updateOrderline(this.orderlineData.guid, {
                        main_status: 6, // Send
                        tracking_number: result.trackerCode,
                        tracking_url: trackingUrl,
                        actual_shipping_date: this.formatToSQLDate(new Date()),
                    })
                        .then(() => {
                            this.orderlineData.main_status = 6;
                            this.trackingNumber = result.trackerCode;
                            this.trackingURL = trackingUrl;
                            this.syncOrderLineStatus(this.orderlineData.guid);
                        })
                        .catch((e) => console.log(e));
                })
                .catch((e) => console.log(e));

            this.validatedAddresses = [];
            this.sendData = {};
        },
        async redownloadSendLabel() {
            const carrier = this.trackingURL.includes("dhl") ? "DHL" : "PostNL";
            await this.getSendlabelWithTrackerCode(this.trackingNumber, carrier)
                .then((result) => {
                    this.makeObjectURLFromLabel(result);
                })
                .catch((e) => console.log(e));
        },
        async updateTrackingData(evt) {
            // get tracking_code and tracking_url from evt
            evt.preventDefault();
            const formData = new FormData(evt.target);
            // const postData = {};

            for (let [index, value] of formData.entries()) {
                console.log(index, value);
                if (index === "tracking-code") {
                    this.trackingNumber = value;
                }
                if (index === "tracking-url") {
                    this.trackingURL = value;
                }
            }
            return this.updateOrderline(this.orderlineData.guid, {
                tracking_url: this.trackingURL,
                tracking_number: this.trackingNumber,
                actual_shipping_date: this.formatToSQLDate(new Date()),
                main_status: 6,
            }).then(() => {
                this.orderlineData.main_status = 6;
                this.syncOrderLineStatus(this.orderlineData.guid);
                this.closeUpdateTrackingUrlModal();
            });
        },
        makeObjectURLFromLabel(result) {
            var blob = new Blob([result.body], { type: "application/pdf" });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            var fileName = this.orderlineData.seller_order_line_ref + ".pdf";
            link.download = fileName;
            link.click();
            return;
        },
        dissectName() {
            let firstSpace = this.orderlineData.address.name.indexOf(" ");

            return {
                firstName: this.orderlineData.address.name.substring(
                    0,
                    firstSpace
                ),
                lastName: this.orderlineData.address.name.substring(
                    firstSpace,
                    1000
                ),
            };
        },
        showOrderlineCommentModal() {
            this.activateBlockOut();
            const el = document.getElementById(
                "orderline-comment-modal-" + this.orderline.guid
            );
            el.style.display = "block";
        },
        closeCommentModal() {
            this.deactivateBlockOut();
            const el = document.getElementById(
                "orderline-comment-modal-" + this.orderline.guid
            );
            el.style.display = "none";
        },
        async postOrderlineComment(event) {
            event.preventDefault();
            const formData = new FormData(event.target);
            const postData = {};
            for (let value of formData.entries()) {
                postData["body"] = value[1];
            }
            postData["orderlineGuid"] = this.orderline.guid;
            console.log(this.$auth);
            postData["userEmail"] = this.$auth.user.value.email;
            console.log({ postData });
            await this.createOrderlineComment(postData)
                .then((result) => {
                    this.orderlineComments.unshift(result.data);
                    this.deactivateBlockOut();
                    this.closeCommentModal();
                })
                .catch((e) => console.log(e));
        },
        openCheckFileModal() {
            const el = document.querySelector(
                "#file-check-modal-" + this.orderlineData.guid
            );
            el.style.display = "block";
            this.activateBlockOut();
        },
        closeCheckFileModal() {
            const el = document.querySelector(
                "#file-check-modal-" + this.orderlineData.guid
            );
            el.style.display = "none";
            this.deactivateBlockOut();
        },
        async acceptFile() {
            console.log(this.orderlineData);
            this.$swal({
                icon: "success",
                title: "Accepting file",
                text: this.file.guid,

                input: "text",
                inputLabel: "Comment",
                inputValue: "Dit bestand is goedgekeurd.",
                inputValidator: (value) => {
                    if (!value) {
                        return "Comment can not be empty";
                    }
                },

                confirmButtonText: "Accept",
                showCancelButton: true,
            }).then(async (data) => {
                if (data.value) {
                    this.acceptFileAxiosCall(data, this.file.guid).then(() => {
                        this.updateOrderlineStatus(this.orderline.guid, {
                            status: "Bestand goedgekeurd",
                            comment: data.value,
                        }).then((result) => {
                            this.orderlineData.statuses.unshift(result);
                            this.updateOrderline(this.orderline.guid, {
                                main_status: 4, //Ready for outsourcing
                            }).then(() => {
                                this.orderlineData.main_status = 4;
                                this.syncOrderLineStatus(
                                    this.orderlineData.guid
                                );
                            });
                        });
                        this.closeCheckFileModal();
                    });
                }
            });
        },
        async declineFile() {
            this.$swal({
                icon: "error",
                title: "Declining file",
                text: this.file.guid,

                input: "text",
                inputLabel: "Opmerking",
                inputValidator: (value) => {
                    if (!value) {
                        return "Comment can not be empty";
                    }
                },

                confirmButtonText: "Decline",
                showCancelButton: true,
            }).then(async (data) => {
                if (data.value) {
                    this.declineFileAxiosCall(data, this.file.guid).then(() => {
                        this.updateOrderlineStatus(this.orderline.guid, {
                            status: "Bestand afgekeurd",
                            comment: data.value,
                        }).then((result) => {
                            this.orderlineData.statuses.unshift(result);
                            this.updateOrderline(this.orderline.guid, {
                                main_status: 2, // File rejected
                            }).then(() => {
                                this.orderlineData.main_status = 2;
                                this.syncOrderLineStatus(
                                    this.orderlineData.guid
                                );
                            });
                        });
                        this.closeCheckFileModal();
                    });
                }
            });
        },
        submitFormByEnter(event, formId) {
            event.preventDefault();
            document
                .getElementById(formId)
                .dispatchEvent(
                    new Event("submit", { cancelable: true, bubbles: true })
                );
        },
        openFilePreview() {
            window.open(this.orderlineData.files[0].url, "_blank");
        },
    },
};
</script>
<style scoped>
h4 {
    font-weight: bold;
}

button {
    font-size: 12px;
}

#wrap {
    padding: 0;
    overflow: hidden;
    position: relative;
}

#frame {
    overflow: hidden !important;
    background-color: white;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 0;
    /* border: 1px solid black; */
    zoom: 1;
    transform: scale(1);
    transform-origin: 0 0;
    -moz-transform: scale(1);
    -moz-transform-origin: 0 0;
}

.bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: red;
    opacity: 0.5;
    z-index: 100;
}

.action-buttons {
    margin-top: 0.5em;
    margin-bottom: 1em;
}

canvas {
    cursor: pointer;
    max-width: 20em;
    max-height: 20em;
    box-shadow: lightgray 0 0 5px;
}

.file-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 2px grey solid;
}

.file-buttons button {
    color: white;
    background-color: #131315;
    margin-bottom: 0.5em;
    width: 8em;
    text-align: left;
    padding: 0;
    display: flex;
    align-items: center;
}

.file-buttons button i {
    padding: 0 0.5em;
}

.file-buttons button div {
    text-align: center;
    display: inline;
    width: 5.5em;
}

.load-layer {
    position: relative;
    height: 30em;
    width: 33em;
    justify-content: center;
    align-items: center;
    bottom: 26em;
    margin-bottom: -30em;
    background-color: rgba(255, 255, 255, 0.8);
    display: none;
}

.accordion-button {
    background-color: #f0f0f0 !important;
}

.accordion .accordion-item {
    border: none;
    padding: 10px 0;
}

.accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: #f0f0f0 !important;
    box-shadow: none;
}

.accordion-button:focus {
    outline: 0;
    box-shadow: none !important;
}

.accordion-header {
    font-weight: bold;
}

.accordion-toggle {
    width: 100%;
    height: 2em;
    display: flex;
    align-items: center;
    font-size: 17px;
    border: 0 !important;
    border-radius: 2px;
    padding: 0;
}

.arrow-container {
    height: 100%;
    display: flex;
    width: 2em;
    background-color: rgb(200, 200, 200);
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-left: 1em;
}

.nextday {
    display: flex;
    background-color: #ff0101;
    height: 100%;
    text-align: center;
    align-items: center;
    padding: 0 1em;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
}

.send-date-status-dot {
    /* background-color: red; */
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    text-align: center;
}

.datepicker {
    display: none;
    position: absolute;
    width: 35em;
}

#edit-send-data {
    cursor: pointer;
}

.edit-send-data-modal {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 10vh;
    left: 30vw;
    width: 40vw;
    height: 80vh;
    background-color: white;
    box-shadow: grey 0px 0px 10px;
    border-radius: 5px;
    padding: 2em 1em;
}

.form-group.row {
    margin: 2em 0;
}

.external-handling-modal {
    display: none;
    z-index: 1100;
    position: fixed;
    top: 10vh;
    left: 30vw;
    width: 40vw;
    height: 80vh;
    background-color: white;
    box-shadow: grey 0px 0px 10px;
    border-radius: 5px;
    padding: 2em 1em;
}

.out-source-modal {
    z-index: 1100;
    display: none;
    position: fixed;
    top: 25vh;
    left: 35vw;
    width: 30vw;
    height: 45vh;
    background-color: white;
    box-shadow: grey 0 0 10px;
    border-radius: 10px;
}

.send-modal {
    z-index: 1100;
    display: none;
    position: fixed;
    top: 25vh;
    left: 45vw;
    width: 20vw;
    height: 40vh;
    background-color: white;
    box-shadow: grey 0 0 10px;
    border-radius: 10px;
}

.send-modal .float-end {
    border-top-right-radius: 10px !important;
}

.send-modal input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin-left: 3.3em;
    border-radius: 5px;
    border: 2px solid #555;
}

.send-modal input[type="text"] {
    text-align: center;
    display: inline;
    width: 40px;
    margin-left: 4em;
}

.manual-tracking-modal {
    z-index: 1100;
    display: none;
    position: fixed;
    top: 20vh;
    left: 40vw;
    width: 30vw;
    height: 50vh;
    background-color: white;
    box-shadow: grey 0 0 10px;
    border-radius: 10px;
}

.manual-tracking-modal .float-end {
    border-top-right-radius: 10px !important;
}

.manual-tracking-modal input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin-left: 3.3em;
    border-radius: 5px;
    border: 2px solid #555;
}

.manual-tracking-modal input[type="text"] {
    text-align: start;
    display: inline;
    width: 100%;
}

#statuses-container {
    max-height: 15em;
    overflow-y: auto;
}

#statuses-container::-webkit-scrollbar {
    width: 10px;
}

#statuses-container::-webkit-scrollbar-track {
    /* background: white; */
    background: #f1f1f1;
}

#statuses-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}

#statuses-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.toggled-off {
    background-color: lightgray !important;
}

.multiple-addresses-modal {
    z-index: 1100;
    display: none;
    position: fixed;
    top: 25vh;
    left: 45vw;
    width: 20vw;
    height: 35vh;
    background-color: white;
    box-shadow: grey 0 0 10px;
    border-radius: 10px;
}

.multiple-addresses-container {
    max-height: 70%;
    overflow-y: auto;
}

.address-block {
    cursor: pointer;
}

.address-block:hover {
    background-color: lightblue;
    border-color: darkblue;
}

.cancel-modal {
    z-index: 1100;
    display: none;
    position: fixed;
    top: 25vh;
    left: 45vw;
    width: 20vw;
    height: 35vh;
    background-color: white;
    box-shadow: grey 0 0 10px;
    border-radius: 10px;
}

.orderline-comment-modal {
    z-index: 1100;
    display: none;
    position: fixed;
    top: 25vh;
    left: 45vw;
    width: 20vw;
    height: 35vh;
    background-color: white;
    box-shadow: grey 0 0 10px;
    border-radius: 10px;
}

.fix-orderline-modal {
    z-index: 1100;
    display: none;
    position: fixed;
    top: 25vh;
    left: 45vw;
    width: 20vw;
    height: 35vh;
    background-color: white;
    box-shadow: grey 0 0 10px;
    border-radius: 10px;
}

.comment-table {
    max-height: 7em;
    overflow: auto;
}

.comment-table:hover {
    box-shadow: lightgray 0 0 10px;
}

.comment-table::-webkit-scrollbar {
    width: 10px;
}

.comment-table::-webkit-scrollbar-track {
    /* background: white; */
    background: #f1f1f1;
}

.comment-table::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}

.comment-table::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.file-check-modal {
    z-index: 1100;
    display: none;
    position: fixed;
    top: 25vh;
    left: 45vw;
    width: 30vw;
    height: 20vh;
    background-color: white;
    box-shadow: grey 0 0 10px;
    border-radius: 10px;
}
</style>
